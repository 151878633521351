.dark_mode {
    margin: auto;
}

.dark_mode_label {
    max-width: 6vw;
    min-width: 90px;
    height: 3.5vh;
    position: relative;
    display: block;
    background: #ebebeb;
    border-radius: 200px;
    box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
        inset 0px -5px 15px rgba(255, 255, 255, 0.4);
    cursor: pointer;
    transition: 0.3s;
}
.nav_theme_link_drop{
    color: var(--textw);
}
.dark_mode_label:after {
    content: "";
    max-width: 1.8vw;
    min-width: 40px;
    max-height: 2.8vh;
    min-height: 22px;
    position: absolute;
    margin: 0.3vh 0.3vw auto 0.3vw;
    left: 0.2vw;
    background: linear-gradient(180deg, #ffcc89, #d8860b);
    border-radius: 180px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}
.dark_mode_input {
    width: 0;
    height: 0;
    visibility: hidden;
}
.dark_mode_input:checked + .dark_mode_label {
    background: #242424;
}
.dark_mode_input:checked + .dark_mode_label:after {
    margin-left: 80px;
    margin-right: 6.5vw;
    transform: translateX(-100%);
    background: linear-gradient(180deg, #777, #3a3a3a);
}
.dark_mode_label:active:after {
    width: 1vw;
}

.dark_mode_label svg {
    position: absolute;
    max-width: 1.2vw;
    min-width: 22px;
    margin: 0.3vh 0.3vw auto 0.3vw;
    z-index: 100;
}
.dark_mode_label svg.sun {
    left: 0.6vw;
    fill: #fff;
    transition: 0.3s;
}
.dark_mode_label svg.moon {
    margin-left: 0.75vw;
    margin-right: 6.5vw;
    fill: #7e7e7e;
    transition: 0.3s;
}
.dark_mode_input:checked + .dark_mode_label svg.sun {
    fill: #7e7e7e;
}
.dark_mode_input:checked + .dark_mode_label svg.moon {
    fill: #fff;
}
.nav_theme{
    background-color: var(--navbg)!important;
    color: var(--textw)!important;

}
.nav_theme_link{
    color: var(--navl)!important;
}
.nav_theme_brand{
    color: var(--textw)!important;
}
.nav_theme_brand:hover{
    color: var(--textw)!important;
}
.nav_theme_link:hover{
    color: var(--navlh)!important;
}
