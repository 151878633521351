body{
    background-color: var(--bg);
}
.about1{
    top: 15vh;
    background-color: var(--bg);
    position: relative;
    min-height: 93vh;
    max-height: fit-content;
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: auto!important;
}
.sp1{
    color: var(--sp1)!important;
    font-weight: 700;
    font-size: 4.5vw;
}
.container{
    align-items: center;
    justify-content: center;
    align-items: center;
    margin-left: auto!important;
    margin-right: auto!important;
    width: 100%!important;
    height: 100%;
}
.img{
    justify-content: center;
    border: 0.75vh solid var(--textw);
    border-radius: 1.5vh;
    object-fit: fill;
    background-color: var(--bgl);
    margin: auto!important;
}
@keyframes zoom-in-out {
    0% {
    transform: scale(1, 1);
    }
    50% {
    transform: scale(1.25, 1.25);
    }
    100% {
    transform: scale(1, 1);
    }
    }
    @keyframes zoom-in-out-auto {
        0% {
        transform: scale(1, 1);
        }
        50% {
        transform: scale(1.05, 1.05);
        }
        100% {
        transform: scale(1, 1);
        }
        }
.img:hover{
    animation: zoom-in-out 6s ease-in-out infinite!important;
}
.img{
    animation: zoom-in-out-auto 6s ease-in-out infinite!important;
}
.content-grid{
    margin: auto!important;
    display: flex;
    flex-direction: column;
    width: 65%;
    min-height: auto;
    max-height: fit-content!important;
}
.h4{
    color: var(--textw);
    margin-top: 5vh;
}
.about2{
    width: 100%;
    background-color: var(--bg);
    height: fit-content;
    margin-top: 20vh!important;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.skill{
    width: 160px;
    height: 160px;
    position: relative;
}
.outer{
    height: 160px;
    width: 160px;
    border-radius: 50%;
    box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.581), -6px -6px 10px -1px rgba(255, 255, 255, 0.735); 
    padding: 20px;
}
.inner{
    height: 120px;
    width: 120px;
    border-radius: 50%;
    box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.55), inset -4px -4px 6px -1px rgba(255, 255, 255, 0.735), -0.5px -0.5px 0px rgb(255, 255, 255), 0.5px 0.5px 0px rgba(0, 0, 0, 0.15), 0px 12px 10px -10px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
}
#number{
    font-weight: 700;
    font-size: 25px;
    color: var(--textwl);
}
circle{
    fill: none;
    stroke: url(#GradientColor);
    stroke-width: 20px;
    stroke-dasharray: 472;
    stroke-dashoffset: 472;
}
.svg{
    position: absolute;
    top: 0;
    left: 0;
}
.circle1{
    animation: anim1 2s linear forwards;
}

@keyframes anim1{
    100%{
        stroke-dashoffset: 94;
    }
}
.circle2{
    animation: anim2 2s linear forwards;
}

@keyframes anim2{
    100%{
        stroke-dashoffset: 164;
    }
}.circle3{
    animation: anim3 2s linear forwards;
}

@keyframes anim3{
    100%{
        stroke-dashoffset: 104;
    }
}.circle4{
    animation: anim4 2s linear forwards;
}

@keyframes anim4{
    100%{
        stroke-dashoffset: 204
    }
}.circle5{
    animation: anim5 2s linear forwards;
}

@keyframes anim5{
    100%{
        stroke-dashoffset: 90;
    }
}.circle6{
    animation: anim6 2s linear forwards;
}

@keyframes anim6{
    100%{
        stroke-dashoffset: 100;
    }
}.circle7{
    animation: anim7 2s linear forwards;
}

@keyframes anim7{
    100%{
        stroke-dashoffset: 134;
    }
}.circle8{
    animation: anim8 2s linear forwards;
}

@keyframes anim8{
    100%{
        stroke-dashoffset: 150;
    }
}.circle9{
    animation: anim9 2s linear forwards;
}

@keyframes anim9{
    100%{
        stroke-dashoffset: 155;
    }
}.circle10{
    animation: anim10 2s linear forwards;
}

@keyframes anim10{
    100%{
        stroke-dashoffset: 185;
    }
}.circle11{
    animation: anim11 2s linear forwards;
}

@keyframes anim11{
    100%{
        stroke-dashoffset: 185;
    }
}.circle12{
    animation: anim12 2s linear forwards;
}

@keyframes anim12{
    100%{
        stroke-dashoffset: 90;
    }
}.circle13{
    animation: anim13 2s linear forwards;
}

@keyframes anim13{
    100%{
        stroke-dashoffset: 80;
    }
}
.circle14{
    animation: anim14 2s linear forwards;
}

@keyframes anim14{
    100%{
        stroke-dashoffset: 85;
    }
}
.featured-h{
    margin-top: 3vh;
    margin-bottom: 15vh!important;
}
.featured-h2{
    margin-top: 12vh!important;
    margin-bottom: 8vh!important;
}
.container-featured{
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}
.grid-featured{
    flex-direction: row;
    display: flex;
}
.featured-content{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.h3{
    color: whitesmoke;
    margin-top: 3vh;
    margin-bottom: auto;
    margin-left:2vw;
}
.about2-1{
    position: relative;
    margin: auto;
    display: flex;
    width: fit-content!important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.about3{
    position: relative;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}
.ed-card{
    width: 40vw!important;
    margin-top: 4vh!important;
    margin-bottom: 4vh!important;
}
.h1{
    margin-top: 15vh;
    color: white;
    margin-bottom: auto;
    font-size: 3.5vw;
    width: fit-content;
}
.ed-container{
    margin-top: auto!important;
    margin-bottom: auto!important;
    display: flex;
    align-items: center;
    min-height: fit-content!important;
    max-height: auto!important;
    flex-direction: column;
    width: 40vw!important;
}

@media screen and (max-width:1230px) {
    .about1{
        width: 50%;
    }
    .content-grid{
        width: 100%;
    }
}
@media screen and (max-width:768px) {
    .about2-1{
        flex-direction: column;
    }
}