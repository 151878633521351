.form-container{
    position: absolute;
    top: 19vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: var(--textw);
  }
  section{
    width:100%;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    top: 0;
    justify-content: center;
    min-height: 100vh;
    max-height: fit-content;
    position: absolute;
  }
form {
  width: 80%;
  min-height: 60vh;
  max-height: fit-content;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: auto auto auto auto !important;
  background-color: var(--form);
  border-radius: 20px;
  flex-direction: column;
  position: relative;
}
.h1 {
  font-size: 2.5vw;
  font-weight: bold;
  color: var(--formh)!important;
  margin: 8vh auto auto auto!important;
}
.email{
    width: 19vw!important;
}
.name{
    width: 10vw!important;
}
.subject{
    width: 28vw!important;
}
.msg{
    width: 30vw!important;
}
.form-group{
    transition: all .3s;
    margin-bottom: 1em;
}

.form-group:focus-within{
    transform: scale(1.15,1.15);
}
.select-label{
    font-size: larger;
    font-weight: bold;
    color: var(--textw);
}
.select{
    border-radius: 7px;
    font-size: 1.25em;
    text-align: center;
}
.btn:focus-within,
.btn:hover{
    color: white;
}
.input,.input2{
    margin: auto!important;
}
.input:focus-within{
    transform: scale(1.15,1.15);
    margin-bottom: auto;
}
.input2:focus-within{
    transform: scale(1.1,1.1);
    margin-bottom: auto!important;
}
.container2{
    margin: auto 3vw 12vh 3vw!important;
    width: fit-content;
    flex-direction: column;
}
.container-form{
    flex-direction: row;
    align-items: center;
    width: 100%;
    display: flex;
    align-self: center;
    align-content: center;
    justify-content: center;
    height: 100%;
}
.Grid{
    margin: auto!important;
}
@media screen and (max-width:1024px) {
    .container-form{
        flex-direction: column;
    }
    .container2{
        margin: auto!important;
    }
    form{
        margin-top: 10vh!important;
        margin-bottom: 10vh!important;
    }
}