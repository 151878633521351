:root{
  --bg:rgba(255, 255, 255, 0.998);
  --textw: black;
  --navbg: #f8f9fa;
  --bgl: rgba(255, 255, 255, 0.598);
  --navl: #575757;
  --navlh: #323232;
  --sp1: rgb(141, 43, 221);
  --sp2: #2758df;
  --projectb: rgb(90, 182, 90);
  --textwl: #6c6c6c;
  --form: #c5c5c5;
  --formh: #161515dc;
  --workg: #c75264;
  --workt: #d6d6d6;
  --workpb: #5961fe;
  --workpbg: #a83d3d;
}
*{
transition: all 0.1s ease-in-out;
}
[data-theme="dark"]{
  --bg:rgba(0, 0, 0, 0.998);
  --textw: white;
  --navbg: #323234;
  --bgl: rgba(0, 0, 0, 0.226);
  --navl: #b5b7b8;
  --navlh: #d5d6d7;
  --sp1: rgb(169, 94, 239);
  --sp2: #7d9efa;
  --projectb: rgb(154, 235, 154);
  --textwl: #c4c4c4;
  --form: #222222eb;
  --formh: rgba(255, 255, 255, 0.598);
  --workg: #d0475b;
  --workt: #171717;
  --workpb: #898af2;
  --workpbg: #ff6767;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
