body{
    background-color: var(--bg);
}
.nav:hover{
    color: whitesmoke;
}
.main{
    height:100vh;
    width:100%;
    background-color: var(--bg);
}
video{
    width:100%;
    height:100%;
    object-fit: contain;
}
.content{
    position: absolute;
    width: 100%!important;
    height: 90vh!important;
    margin-top:5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--textw);
    align-items: center;
    align-content: center;
}
.grid{
    margin: auto!important;
}
.content2{
    position: relative;
    width: 100%;
    min-height: 95vh;
    max-height: fit-content;
    margin: auto!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--textw);
    background-color: var(--bg);
}
.overlay{
    position: absolute;
    top: 7vh;
    left:0;
    width: 100%;
    height: 93vh   ;
    background-color: var(--bg);
}
.sp1{
    color: var(--sp1)!important;
    font-weight: 700;
    font-size: 4.5vw;
}
.sp2{
    color: var(--sp2)!important;
    font-weight: 700;
    font-size:4vh;
}
.startup-card{
    width:100%;
    height:auto;
    align-items: center;
    align-content: center;
    margin: auto auto auto auto;
}
.a{
    text-decoration: none;
    color: white;
}
.enquiry-a{
    text-decoration: none;
    color: black;
}
.enquiry-a:hover{
    text-decoration: none;
}
.content3{
    position: relative;
    width: 100%;
    min-height: 15vh;
    max-height: auto;
    margin-top:5vh!important;
    margin-bottom: 5vh!important;
    display: flex;
    max-height: fit-content;
    min-height: 15vh;
    justify-content: center;
    color: var(--textw);
    background-color: var(--bg);
}
.content4{
    position: relative;
    width: 100%;
    min-height: 150vh;
    margin-top:15vh;
    display: flex;
    flex-direction: column;
    max-height: fit-content;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    color: var(--textw);
    background-color: var(--bg);
}
.content5{
    position: relative;
    width: 100%;
    height: 80vh;
    margin-top:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--textw);
    background-color: var(--bg);
    border-radius: 5px;
}
.achievement{
    position:relative;
    max-width: 80vw;
    min-width: 60vw;
    max-height: fit-content!important;
    margin-bottom: 10vh!important;
    margin-top: 10vh!important;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-self: center;
    color: var(--textw);
    background-color: var(--bg);
}
.achievecard:hover{
    transform: scale(1.25,1.25);
}
.achievecard{
    width:25vw!important;
}
.card{
    width:25vw;
    height: 35vh;
background-color: var(--bg);
object-fit: contain;
}
.content5-1{
    background-color: var(--bg);
    margin: auto auto auto auto;
    width: 85%;
    min-height: 8vh;
    max-height: auto;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row!important;
    align-items: center;
    border: 0.5vh solid var(--projectb);
    border-radius: 15px;
    box-shadow: 1px 5px 11px var(--textw);
}
.start-project{
    margin: auto;
}
.interested{
    width: 40vw;
    margin: auto;
}
.enquiry{
    margin: auto;
    border-radius: 20px;
}
.enquiry-a:focus-within,
.enquiry-a:hover,
.enquiry:focus-within,
.enquiry:hover{
    color: white;
}
.social{
    margin: auto auto auto auto;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}
.icon{
    margin: 1vh auto auto auto;
    border-radius: 40px;
    border: 0.4vh solid var(--textw);
    padding: 30px;
    color: var(--textw);
}
.icon:hover{
    color: var(--bg);
    background-color: var(--textw);
}
.icon-a{
    position: relative;
}
h1,h3{
    margin: 3vh 2vw 0 2vw!important;
    color: var(--textw)!important;
}
h1{
    font-size: 3.5vw;
}
h2{
    margin: auto;
}
.Grid{
    margin: 2vh auto 2vh auto!important;
}
.card-c3{
    background-color: var(--sp1)!important;
    margin: auto 1vw 1vh 1vw!important;
    align-content: center;
    text-align: center;
    width: 15vw!important;
}
.Grid2{
    margin: auto!important;
}
.grid-container-c3{
    width: 100%!important;
}

@media screen and (max-width:1024px) {
    h1{
        font-size: 35.84px!important;    
    }
    .sp1{
        font-size: 46.08px!important;
    }
}
@media screen and (max-width:1100px) {
    .startup-card{
        width: 30%;
    }
}
@media screen and (max-width:1000px) {
    .grid-container-c3{
        width: 40%;
    }
    .card-c3{
        width: 100%;
    }
    .achievecard{
        width:60vw!important;
    }
}
@media screen and (max-width:768px) {
    .grid-container-c3{
        width: 30%;
    }
    .card-c3{
        width: 100%;
    }
}
@media screen and (max-width:600px) {
    .content5-1{
        flex-direction: column!important;
        width: 65%;
    }
    .interested{
        width: 100%;
        margin-bottom: 1vh;
    }
    .start-project{
        margin-bottom: 1vh;
        margin-top: 1vh;
    }
}
@media screen and (max-width:530px) {
    .social{
        flex-direction: column;
    }    
}
